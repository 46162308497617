@import './prismjs';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
}

a {
  text-decoration: none;
}

:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}

@font-face {
  font-family: 'proxima nova';
  font-style: 'normal';
  font-weight: 300;
  src: url('../fonts/ProximaNova-Light.woff') format('woff');
};

@font-face {
  font-family: 'proxima nova';
  font-style: 'normal';
  font-weight: 400;
  src: url('../fonts/ProximaNova-Regular.woff') format('woff');
};

@font-face {
  font-family: 'proxima nova';
  font-style: 'normal';
  font-weight: 600;
  src: url('../fonts/ProximaNova-Bold.woff') format('woff');
};

@font-face {
  font-family: 'sharp sans';
  font-style: 'normal';
  font-weight: 200;
  src: url('../fonts/SharpSans-Thin.woff') format('woff');
};

@font-face {
  font-family: 'sharp sans';
  font-style: 'normal';
  font-weight: 300;
  src: url('../fonts/SharpSans-Light.woff') format('woff');
};

@font-face {
  font-family: 'sharp sans';
  font-style: 'normal';
  font-weight: 400;
  src: url('../fonts/SharpSans-Book.woff') format('woff');
};

@font-face {
  font-family: 'sharp sans';
  font-style: 'normal';
  font-weight: 500;
  src: url('../fonts/SharpSans-Medium.woff') format('woff');
};

@font-face {
  font-family: 'sharp sans';
  font-style: 'normal';
  font-weight: 600;
  src: url('../fonts/SharpSans-Semibold.woff') format('woff');
};

@font-face {
  font-family: 'sharp sans';
  font-style: 'normal';
  font-weight: 700;
  src: url('../fonts/SharpSans-Bold.woff') format('woff');
};

@font-face {
  font-family: 'sharp sans';
  font-style: 'normal';
  font-weight: 800;
  src: url('../fonts/SharpSans-Extrabold.woff') format('woff');
};

[data-command=merge_tag] { 
  width: 100px !important;
}